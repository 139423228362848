@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$primary: #4ab493;
$primaryDark: #248271;
$whiteText: #f6f6f6;
$darkText: #1e1e1e;
$disabled: #f3f3f3;
$gradientGreen: linear-gradient(90deg, #4ab493, #0d645d);
$gradientBlack: linear-gradient(180deg, #707070, #1c1c1c);

*,
*::after,
*::before {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif;
}


.Parent_Container_Terms {
  background-color: #f8f8ff;
}
.Terms_Content_Container {
  min-height: 100vh;
  width: 90%;
  background-color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 20px;
  padding-bottom: 30px;
  box-shadow: var(--elevation);
  margin-top: 8%;
}
.Terms_Text {
  font-size: 2rem;
  text-align: center;
  margin-top: 25px;
  color: $primary;
  font-family: var(--font-family-Messiri);
  font-weight: 600;
  padding-top: 20px;
}
.Text_Conatiner {
  /* background-color: green; */
  padding: 0 50px;
}
.Header_Points_Text {
  font-size: 2rem;
  font-family: var(--font-family-open);
  font-weight: bold;
  color: #511b3b;
  margin-bottom: 15px;
}
.Heading_Text {
  font-size: 1.7rem;
  font-family: var(--font-family-open);
  font-weight: bold;
  padding-left: 25px;
  color: #511b3b;
  margin-bottom: 15px;
}
.disrciption_Text_Terms {
  font-size: 1.1rem;
  font-family: var(--font-family-open);
  color: #707070;
  margin-bottom: 15px;
}

@media screen and (max-width: 770px) {
  .Terms_Content_Container {
    margin-top: 25%;
    width: 95%;
    margin-bottom: 0px;
   

  }
  .Text_Conatiner {
    /* background-color: green; */
    padding: 0 50px;
  }
  .Terms_Text {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 25px;
    color: $primary;
    font-family: var(--font-family-Messiri);
    font-weight: 600;
    padding-top: 20px;
  }
  .Header_Points_Text {
    font-size: 1.6rem;
    font-family: var(--font-family-open);
    font-weight: bold;
    color: #511b3b;
    margin-bottom: 15px;
    text-align: center;
  }
  .Heading_Text {
    font-size: 1.5rem;
    font-family: var(--font-family-open);
    font-weight: bold;
    padding-left: 25px;
    color: #511b3b;
    margin-bottom: 15px;
    text-align: center;
  }
  .disrciption_Text_Terms {
    font-size: 1rem;
    font-family: var(--font-family-open);
    color: #707070;
    margin-bottom: 15px;
    text-align: justify;
  }
}
