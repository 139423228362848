.contact__container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}
.contact__container__jumbotron {
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
}
.contact__container__jumbotron__img {
  width: 100%;
  height: 100%;
}
.contact__container__jumbotron__content {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact__container__jumbotron__content__heading {
  font-size: 25px;
  color: #3a3a3a;
  font-weight: 600;
}
.contact__container__jumbotron__content__heading span {
  color: $primary;
}
.contact__container__jumbotron__content__sub__heading {
  font-size: 20px;
  color: #3a3a3a;
}
.contact__container__form__wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 600px;
}
.contact__container__form {
  position: absolute;
  width: 100%;
  border-radius: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 1440px;
  background: linear-gradient(45deg, #ffffff, #f1f1f1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.contact__container__form__header {
  display: flex;
  align-items: center;
  padding: 1.5em 2em;
  border-radius: 20px;
  background: linear-gradient(90deg, #707070, #242424);
}
.contact__container__form__header > div {
  flex: 1;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
}
.contact__container__form__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact__container__form__content__left {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 2em;
}

.contact__container__form__content__left__input,
.contact__container__form__content__left__select {
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  margin-bottom: 1em;
  border-bottom: 1px solid #dbdbdb;
}
.contact__container__form__content__left__input:hover,
.contact__container__form__content__left__select:hover,
.contact__container__form__content__left__input:focus,
.contact__container__form__content__left__select:focus {
  transition: 300ms ease-in-out;
  border-color: $primary;
}
.contact__container__form__content__left__textarea {
  border: none;
  width: 100%;
  outline: none;
  background-color: transparent;
  margin-bottom: 1em;
  border-bottom: 1px solid #dbdbdb;
}
.contact__container__form__content__left__textarea:hover,
.contact__container__form__content__left__textarea:focus {
  transition: 300ms ease-in-out;
  border-color: $primary;
}
.contact__container__form__content__left__textarea::-webkit-scrollbar {
  width: px;
}
.contact__container__form__content__left__textarea::-webkit-scrollbar-track {
  background-color: rgb(233, 233, 233);
}
.contact__container__form__content__left__textarea::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 50px;
}
.contact__container__form__content__left__button {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: auto;
  background: $gradientGreen;
  color: #ffffff;
}
.contact__container__form__content__left__button:hover {
  transition: 300ms ease-in-out;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}
.contact__container__form__content__right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0em 6em;
}
.contact__container__form__content__right__links {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0em;
}
.contact__container__form__content__right__links__link {
  color: #242424;
  margin-right: 2em;
  cursor: pointer;
}
.contact__container__form__content__right__links__link svg {
  width: 20px;
  height: 20px;
}
.contact__container__form__content__right__links__link:hover {
  color: $primary;
}
.contact__container__form__content__right__data {
  display: flex;
  flex-direction: column;
}
.contact__container__form__content__right__heading {
  font-size: 18px;
  color: $primary;
  font-weight: 600;
  margin-top: 2em;
}
.contact__container__form__content__right__entry {
  font-size: 14px;
  color: $darkText;
  margin-top: 0.2em;
  text-decoration: none;
}
@media (max-width: 1440px) {
  .contact__container__jumbotron {
    height: 500px;
  }
}
@media (max-width: 1000px) {
  .contact__container__jumbotron {
    height: 400px;
  }
}
@media (max-width: 800px) {
  .contact__container__form__header__right {
    display: none;
  }
  .contact__container__form__content {
    flex-direction: column;
  }
  .contact__container__form__content__left,
  .contact__container__form__content__right {
    width: 100%;
  }
  .contact__container__form__wrapper {
    height: 300px;
  }
}
@media (max-width: 700px) {
  .contact__container__jumbotron {
    height: 500px;
  }

  .contact__container__form__content__right {
    padding: 0em 2em;
  }
  .contact__container__jumbotron__img {
    width: 300%;
  }
}
