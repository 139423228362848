.about__us__container__jumbotron {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.about__us__container__jumbotron__main__bg {
  width: 100%;
}
.about__illustration {
  width: 480px;
}
.about__us__container__jumbotron__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  max-width: 1440px;
}
.about__us__container__jumbotron__overlay__heading {
  font-size: 30px;
  color: $darkText;
  font-weight: 600;
  margin-bottom: 0.2em;
  max-width: 40%;
  text-align: center;
}
.about__us__container__jumbotron__overlay__heading span {
  color: $primary;
}
.about__us__container__jumbotron__overlay__para {
  font-size: 22px;
  color: $darkText;
}
.services__container {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.our__story__container {
  width: 100%;
  height: 550px;
  position: relative;
}
.our__story__container__img {
  width: 100%;
  opacity: 0.1;
}
.our__story__container__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
}
.our__story__container__overlay__wrapper {
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  min-height: fit-content;
  display: flex;
  align-items: center;
  padding: 0em 2em;
}
.our__story__container__overlay__wrapper img {
  max-width: 40%;
  margin-left: 10%;
}
.our__story__container__overlay__content {
  width: 100%;
}
.our__story__container__overlay__content__para {
  font-size: 15px;
  color: #242424;
  margin: 1em 0em;
}
.our__story__container__overlay__heading {
  font-size: 25px;
  color: $primary;
  font-weight: 700;
  transform: rotate(270deg);
  position: absolute;
  top: 35%;
  left: 64%;
  display: none;
}
.our__story__container__overlay__content__para__heading {
  font-size: 25px;
  color: $primary;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5em;
}
.our__vision__container {
  width: 100%;
  height: fit-content;
  min-height: 600px;
  position: relative;
}
.our__vision__container__img {
  width: 100%;
}
.our__vision__container__overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.our__vision__container__overlay__container {
  max-width: 1440px;
  width: 100%;
  height: fit-content;
}
.our__vision__container__overlay__container__content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4em 0em;
}
// .our__vision__container__overlay__container__content__img__card {
//   width: 330px;
//   height: 280px;
//   border-radius: 20px;
//   justify-content: center;
//   display: flex;
//   align-items: center;
//   background-color: #ffffff;
// }
.our__vision__container__overlay__container__content__svg svg {
  height: 300px;
}
.our__vision__container__overlay__container__content__text__container {
  display: flex;
  flex-direction: column;
  max-width: 45%;
}
.our__vision__container__overlay__container__content__img {
  width: 500px;
}
.our__vision__container__overlay__container__content__text__container__content {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;
  border-radius: 20px;
  cursor: pointer;
}
.our__vision__container__overlay__container__content__text__container__content:hover {
  background: $gradientGreen;
  transition: 300ms ease-in-out;
}
.our__vision__container__overlay__container__content__text__container__content:hover
  .our__vision__container__overlay__container__content__heading,
.our__vision__container__overlay__container__content__text__container__content:hover
  .our__vision__container__overlay__container__content__text {
  color: $whiteText;
}
.our__vision__container__overlay__container__content__heading {
  font-size: 18px;
  font-weight: 600;
  color: $darkText;
  margin-bottom: 0.2em;
}
.our__vision__container__overlay__container__content__text {
  font-size: 14px;
  color: $darkText;
}
.gallary__container {
  display: flex;
  width: 100%;
  height: 500px;
  position: relative;
}
.gallary__container__img {
  width: 100%;
  max-height: 100%;
}
.gallary__container__content {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.gallary__container__content__wrapper {
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gallary__container__content__wrapper__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6em;
  padding: 0em 2em;
  flex-wrap: wrap;
}
.gallary__container__content__wrapper__content__img {
  width: 24%;
  border-radius: 10px;
}
@media (max-width: 1360px) {
  .about__us__container__jumbotron__overlay__heading {
    max-width: 70%;
  }
}
@media (max-width: 1000px) {
  .why__choose__us__container__content__heading__container {
    margin-bottom: 4em;
  }
  .our__story__container__overlay__wrapper {
    flex-direction: column;
  }
  .our__story__container__overlay__wrapper img {
    max-width: 70%;
    margin-left: 0em;
  }
  .our__story__container__overlay__heading {
    display: none;
  }
  .our__vision__container {
    margin-bottom: 5em;
    height: 800px;
  }
  .our__vision__container__overlay__container__content {
    flex-direction: column;
    margin-top: -1em;
  }
  .our__vision__container__overlay__container__content__svg {
    display: none;
  }
  .our__vision__container__overlay__container__content__text__container {
    max-width: 100%;
    flex-direction: row;
    padding: 0em 2em;
  }
  .gallary__container {
    height: 700px;
  }
  .gallary__container__content__wrapper__content__img {
    width: 49%;
    margin-bottom: 1em;
  }
}
@media (max-width: 650px) {
  .our__story__container {
    height: 900px;
  }
  .our__story__container__overlay {
    position: relative;
  }
  .our__vision__container__overlay__container__content__text__container {
    flex-direction: column;
  }
  .about__us__container__jumbotron__main__bg {
    display: none;
  }
  .our__story__container__img {
    display: none;
  }
  .about__us__container__jumbotron__overlay__heading {
    font-size: 20px;
  }
  .gallary__container {
    height: fit-content;
  }
  .gallary__container__img {
    display: none;
  }
  .gallary__container__content {
    position: relative;
    margin-bottom: 6em;
  }
  .gallary__container__content__wrapper__content__img {
    width: 100%;
    margin-bottom: 1em;
  }
}
@media (max-width: 500px) {
  .why__choose__us__container__content__heading__container {
    margin-top: 8em;
  }
  .our__vision__container__overlay__container__content__img {
    width: 320px;
  }
  .about__us__container__jumbotron__overlay__heading {
    max-width: 95%;
  }
  .about__us__container__jumbotron {
    height: 600px;
  }
  .main__illustration {
    width: 350px;
  }
  .about__us__container__jumbotron__overlay__heading {
    font-size: 24px;
  }
  .about__us__container__jumbotron__overlay__para {
    font-size: 18px;
  }
  .our__story__container {
    height: 1050px;
  }
  .our__vision__container__overlay__container__content__text__container {
    height: 1000px;
    width: 100%;
  }
  .our__vision__container__overlay__container__content__img {
    width: 280px;
  }
  .why__choose__us__container__content__heading__container {
    margin-top: 14em;
  }
  .our__story__container__overlay__content__para {
    margin: 2em;
  }
  .our__vision__container__overlay__container__content__text__container__content {
    padding: 1em;
  }
}
